import { template as template_fad14b8e47e64077bb3f34bc09f65331 } from "@ember/template-compiler";
const FKLabel = template_fad14b8e47e64077bb3f34bc09f65331(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
