import { template as template_8d14350314e84024a2bdf77d3c09a22b } from "@ember/template-compiler";
const SidebarSectionMessage = template_8d14350314e84024a2bdf77d3c09a22b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
