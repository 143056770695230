import { template as template_461772b2c9b9498d83717b9c99c1dfb0 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import AboutPageUser from "discourse/components/about-page-user";
export default class LegacyAboutPageUsers extends Component {
    get users() {
        return this.args.users || [];
    }
    static{
        template_461772b2c9b9498d83717b9c99c1dfb0(`
    {{#each this.users as |user|}}
      <AboutPageUser @user={{user}} />
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
