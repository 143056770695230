import { template as template_d49bd2b85afc4d50b9c4fe63e2c39ccc } from "@ember/template-compiler";
const FKText = template_d49bd2b85afc4d50b9c4fe63e2c39ccc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
