import { template as template_dad88b8250e042d8af1937888f814f11 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_dad88b8250e042d8af1937888f814f11(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
